<template lang="pug">
.filters-content
    UserFilter(
        v-for="item in data.vacancies"
        :key="item.id"
        :data="item"
        :class="[selectedFilterId === item.id ? 'selected-user-filter' : '']"
        @setSelected="setSelected"
        showTag
    )
    .divider
    .open-button(@click="descriptionShow=!descriptionShow")
        h3 ᲐᲦᲬᲔᲠᲐ
        img(src="@/assets/images/Icon-ionic-ios-arrow-up.svg" :class="{rotate: descriptionShow}")
    .filter-information(:class="{open: descriptionShow}")
        p(v-html="data.description")
        .filter-date
            img(src="@/assets/images/Icon-feather-calendar.svg")
            p {{ postDuration }}
        .filter-information-bottom
            h3 {{ postSalary }}
            .filter-information-bottom-right
                p {{ data.interests }}
                img(src="@/assets/images/Icon-ionic-ios-heart-empty-black.svg")
</template>

<script>
import UserFilter from "@/components/layout/UserFilter";
import dateFormat from "@/middleware/dateFormat";
import TokenService from "@/services/token.service";

export default {
    name: "filtersContent",
    components: {UserFilter},
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        removeSelected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            descriptionShow: true,
            selectedFilterId: null
        }
    },
    mounted() {
        this.selectedFilterId = parseInt(TokenService.getItem('selectedFilter')) ? parseInt(TokenService.getItem('selectedFilter')) : null
    },
    watch: {
        removeSelected(nVal) {
            if (nVal) this.selectedFilterId = null
        }
    },
    computed: {
        postSalary() {
            return this.data.is_salary ? "$ ანაზღაურებადი" : " "
        },
        postDuration() {
            return dateFormat(this.data.start_date).dayNumber + " " + dateFormat(this.data.start_date).month + " - " + dateFormat(this.data.end_date).dayNumber + " " + dateFormat(this.data.end_date).month
        }
    },
    methods: {
        setSelected(val) {
            this.selectedFilterId = val
            this.$emit('selectedFilterId', val)
            TokenService.setItem('selectedFilter', val)
        }
    }
}
</script>

<style lang="scss" scoped>
.filters-content {
    padding: 25px 20px;

    :deep(.user-filter) {
        cursor: pointer;
        margin-bottom: 10px;
        padding: 7px;
        border: 1px solid #FFFFFF;
    }

    :deep(.user-filter.selected-user-filter) {
        border-color: #4354B4 !important;
        border-radius: 5px;
    }

    > .divider {
        width: 100%;
        height: 1px;
        background-color: #DDDDDD;
        margin: 10px 0;
    }

    > .open-button {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 15px;

        h3 {
            font: normal normal bold 13px/15px 'Helvetica-Bold';
            letter-spacing: 0;
            color: #212121;
            cursor: pointer;
        }

        img {
            transition: .3s;
        }

        img.rotate {
            transform: rotate(180deg);
        }
    }

    .filter-information {

        > p {
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0;
            color: #6E6E6E;
            text-align: left;
            margin-bottom: 10px;
        }

        .filter-date {
            display: flex;
            justify-content: right;
            gap: 6px;


            span {
                color: #4354B4;
            }

            p {
                font-size: 14px;
                color: #4354B4;
            }
        }

        .filter-information-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            h3 {
                font: normal normal bold 14px/16px 'Helvetica-Bold';
                color: #4354B4;
            }

            .filter-information-bottom-right {
                display: flex;
                align-items: center;
                gap: 5px;

                p {
                    font-size: 16px;
                    color: #6E6E6E;
                }

                img {
                    width: 25px;
                    height: 24px;
                }
            }
        }
    }

    .filter-information {
        width: 100%;
        max-height: 0;
        transition: .4s;
        opacity: 0;
        visibility: hidden;
    }

    .filter-information.open {
        max-height: 100vh;
        opacity: 1;
        visibility: visible;
        margin-top: 15px;
    }
}
</style>
