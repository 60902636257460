<template lang="pug">
.post-detail
	.post-detail-toggles
		.toggle-one
			span მხოლოდ მე
			Toggle(v-if="localIsPublic !== null" v-model="localIsPublic"
				@change="val => this.handleToggleChange(val, 'is_published')")
			span საჯარო
		.toggle-two
			span არა აქტიური
			Toggle(v-if="localIsActive !== null" v-model="localIsActive"
				@change="val => this.handleToggleChange(val, 'is_active')")
			span აქტიური
	.post-detail-wrapper
		.post-detail-middle
			.interested-people-list
				h3 ᲓᲐᲘᲜᲢᲔᲠᲔᲡᲔᲑᲣᲚᲘ
				.interested-people-list-wrapper
					.item-of-person(
						v-for="offer in activeOffers"
						:key="offer.id"
						@click="activeModelBiography = offer.biography; offerStatus = offer.status"
						:class="[offer.biography === activeModelBiography? 'selected' : '', offer.status === 0 ? 'yellow' : offer.status === 2 ? 'red' : '' ]"
					)
						div
							img(:src="offer.biography.user.profile_image !== null ? offer.biography.user.profile_image : require('../assets/images/defaultImage.png')")
							span {{ offer.status === 0 || offer.status === 2 ? offer.biography.user.first_name[0] + "." + offer.biography.user.last_name[0] : offer.biography.user.first_name + " " + offer.biography.user.last_name }}
						.item-of-person-buttons(v-if="offer.status === 0" )
							button.reject(@click="changeOfferStatus(2, offer.id)") უარი
							button.accept(@click="changeOfferStatus(1, offer.id)") თანხმობა
						.item-of-person-buttons(v-if="offer.status === 2")
							button.suggest(@click="changeOfferStatus(1, offer.id)") შეთანხმება

			.interested-person-info
				ActorDetail(:data="activeModelBiography" :offerStatus="offerStatus")
			.post-info
				h3 ᲤᲘᲚᲢᲠᲔᲑᲘ
				.post-info-wrapper
					FiltersContent(:data="postData" @selectedFilterId="setFilters" :removeSelected="removeSelected")
		.post-detail-search(ref="postDetailSearchRef" )
			Filters(buttonText="ᲤᲝᲢᲝ ᲫᲘᲔᲑᲐ" @removeSelected="removeSelected=true" @fetchPageData="handleFetchData"
				:runFilter="runFilter" filterBiography)
			.search-result
				h3 ᲫᲘᲔᲑᲘᲡ ᲨᲔᲓᲔᲒᲔᲑᲘ
				.search-result-listing
					.listing-item(v-for="item in biographies.results" :key="item.id")
						ActorDetail(:data="item" onResult)
</template>

<script>
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";

import Toggle from '@vueform/toggle'
import "@vueform/toggle/themes/default.css"

import Filters from "../components/home/Filters";
import Post from "../components/home/Post";
import FiltersContent from "../components/post-detail/FiltersContent";
import ActorDetail from "../components/post-detail/ActorDetail";
import TokenService from "../services/token.service";
import tokenService from "../services/token.service";

export default {
	name: "PostDetail",
	components: {
		ActorDetail,
		FiltersContent,
		Post,
		Filters,
		Toggle
	},
	setup() {
		const toast = useToast()
		return {toast}
	},
	data() {
		return {
			localIsPublic: null,
			localIsActive: null,
			removeSelected: false,
			offerStatus: null,
			runFilter: false,
			activeModelBiography: {
				user: {
					first_name: '',
					last_name: '',
					phone_number: "**** *** ** ** **",
					email: "************@*****.***",
					profile_image: null
				},
				other_characteristics: [
					{
						title: ''
					}
				]
			}
		}
	},
	computed: {
		...mapGetters({
			postData: 'posts/post',
			biographies: 'user/getBiographies',
			activeOffers: 'user/activeOffers'
		})
	},
	watch: {
		postData: {
			handler({is_published, is_active}) {
				this.localIsPublic = is_published
				this.localIsActive = is_active
			},
			deep: true
		},
		removeSelected(NewVal) {
			if (NewVal) {
				this.runFilter = false
			}
		}
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
	},
	unmounted() {
		tokenService.removeItem('selectedFilter')
		this.$store.commit('posts/setSelectedFilter', [])
		this.$store.commit('posts/setFiltersBreadcrumb', [])
		window.removeEventListener("scroll", this.handleScroll)
	},
	methods: {
		handleFetchData(query) {
			this.$store.dispatch('user/fetchActiveOffers')
			this.$store.dispatch('posts/fetchSinglePost', this.$route.params.postId).then(() => {
				if (TokenService.getItem('selectedFilter') !== null) {
					this.setFilters(parseInt(TokenService.getItem('selectedFilter')))
				}
			})
			this.$store.dispatch("user/userData")
			if (query !== null) this.$store.dispatch("user/fetchBiographies", query)
			else this.$store.dispatch("user/fetchBiographies")
			this.$store.dispatch("posts/fetchFilters")
		},
		handleToggleChange(val, key) {
			const updateData = {}
			updateData[key] = val
			this.$store.dispatch('posts/partialPostUpdate',
				{postId: Number(this.$route.params.postId), data: updateData}).then(() => {
				this.toast.success('პოსტის მონაცემი წარმატებით შეიცვალა')
				this.$store.dispatch('posts/fetchSinglePost', this.$route.params.postId)
			})
		},
		changeOfferStatus(status, offerId) {
			this.$store.dispatch('user/updateOffer', {status, offerId})
		},
		handleScroll() {
			const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
			if (bottomOfWindow && this.biographies.next) {
				this.$store.dispatch('user/loadMoreBiographies')
			}
		},
		setFilters(id) {
			this.removeSelected = false
			const filters = this.postData.vacancies.find(vacancy => vacancy.id === id)
			const data = []
			filters.eye_colors.forEach(el => {
				data.push({
					filterId: "eye_color",
					id: el.id,
					image: el.image,
					title: `თვალის ფერი: ${el.title}`
				})
			})
			filters.hair_colors.forEach(el => {
				data.push({
					filterId: "hair_color",
					id: el.id,
					image: el.image,
					title: `თმის ფერი: ${el.title}`
				})
			})
			filters.other_characteristics.forEach(el => {
				data.push({
					filterId: "characteristics",
					id: el.id,
					title: `სხვა მახასიათებლები: ${el.title}`
				})
			})
			filters.skin_colors.forEach(el => {
				data.push({
					filterId: "skin_color",
					id: el.id,
					image: el.image,
					title: `კანის ფერი: ${el.title}`
				})
			})
			data.push({
				filterId: "gender",
				id: filters.gender,
				title: `სქესი: ${filters.gender === 1 ? 'მამრობითი' : filters.gender === 2 ? 'მდემრობითი' : 'სხვა'}`,
			})
			data.push({
				filterId: "age",
				title: `ასაკი: ${filters.min_age + "-" + filters.max_age}`,
				value: [filters.min_age, filters.max_age]
			})
			data.push({
				filterId: "height",
				title: `სიმაღლე: ${filters.min_height + "-" + filters.max_height}`,
				value: [filters.min_height, filters.max_height]
			})
			data.push({
				filterId: "weight",
				title: `წონა: ${filters.min_weight + "-" + filters.max_weight}`,
				value: [filters.min_weight, filters.max_weight]
			})
			this.$store.commit('posts/setSelectedFilter', data)
			this.runFilter = true
			this.$refs.postDetailSearchRef.scrollIntoView({behavior: 'smooth'});
		}
	}
}
</script>

<style lang="scss" scoped>
.post-detail {
	min-height: calc(100vh - 108px);
	padding-top: 15px;
	display: flex;
	flex-direction: column;

	.post-detail-toggles {
		display: flex;
		justify-content: right;
		gap: 40px;

		.toggle-one, .toggle-two {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;

			span {
				font: normal normal normal 12px/14px 'Helvetica-Regular';
				letter-spacing: 0;
				color: #6E6E6E;
			}
		}

		:deep(.toggle-container) {
			width: 23px;
			height: 15px;

			.toggle {
				width: 23px;
				height: 15px;
				box-sizing: border-box;

				.toggle-handle {
					width: 11px;
					height: 11px;
				}
			}

			.toggle.toggle-on {
				background-color: #4354B4;
				border-color: #4354B4;
			}
		}

		:deep(.toggle-container:focus) {
			box-shadow: none;
		}
	}

	.post-detail-wrapper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		margin-top: 25px;
		width: 100%;

		.post-detail-middle {
			min-height: calc(100vh - 163px);
			display: grid;
			grid-template-columns: minmax(290px, 290px) minmax(0, 1fr) minmax(min-content, 1fr);
			gap: 10px;

			.interested-person-info {
				background: #FFFFFF 0 0 no-repeat padding-box;
				box-shadow: 0 3px 20px #00000029;
				border-radius: 5px;
				margin-top: 37px;
			}

			.interested-people-list,
			.post-info {
				display: flex;
				flex-direction: column;
				height: 100%;

				h3 {
					margin-bottom: 18px;
					font: normal normal bold 16px/19px 'Helvetica-Regular';
					letter-spacing: 0;
					color: #212121;
				}

				.interested-people-list-wrapper, .post-info-wrapper {
					background: #FFFFFF 0 0 no-repeat padding-box;
					box-shadow: 0 3px 20px #00000029;
					border-radius: 5px;
					height: 100%;
					max-height: calc(100vh - 200px);
					overflow: auto;
				}

				.interested-people-list-wrapper {

					.item-of-person {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 13px 15px;
						border-bottom: 1px solid #DDDDDD;
						cursor: pointer;

						&.yellow > div > span {
							background-color: #FAEBC4;
						}

						&.red > div > span {
							background-color: #FBAEAE;
						}

						> div {
							display: flex;
							gap: 5px;

							> img {
								width: 29px;
								height: 29px;
								border-radius: 50%;
								object-fit: cover;
							}

							> span {
								padding: 4px 9px;
								font: normal normal normal 14px/20px 'Helvetica-Regular';
								letter-spacing: 0;
								color: #262826;
								background-color: #C7F5C5;
								border-radius: 5px;
							}
						}

						.item-of-person-buttons {
							display: flex;
							align-items: center;
							gap: 5px;

							> button {
								border: none;
								cursor: pointer;
								font: normal normal bold 10px/12px 'Helvetica-Regular';
								letter-spacing: 0;
								color: #FFFFFF;
								text-transform: uppercase;
								padding: 4px 9px;
								border-radius: 5px;
							}

							> button.reject {
								background-color: #F86363;
							}

							> button.accept, > button.suggest {
								background-color: #92EB8E;
							}
						}
					}

					.item-of-person:last-child {
						border: none;
					}

					.item-of-person.selected {
						background: #EBEDF7 0 0 no-repeat padding-box;
					}
				}

				.interested-people-list-wrapper::-webkit-scrollbar, .post-info-wrapper::-webkit-scrollbar {
					width: 4px;
				}

				.interested-people-list-wrapper::-webkit-scrollbar-track, .post-info-wrapper::-webkit-scrollbar-track {
					background-color: #FFFFFF;
				}

				.interested-people-list-wrapper::-webkit-scrollbar-thumb, .post-info-wrapper::-webkit-scrollbar-thumb {
					background-color: #E0DDDD;
					border-radius: 8px;
				}
			}
		}

		.post-detail-search {
			min-height: calc(100vh - 60px);
			margin: 30px 0;
			display: grid;
			grid-template-columns: 290px 1fr;
			gap: 16px;
			padding-top: 15px;

			.search-result {
				h3 {
					font: normal normal bold 16px/19px 'Helvetica-Regular';
					letter-spacing: 0;
					color: #212121;
					margin-bottom: 15px;
				}

				.search-result-listing {
					height: fit-content;
					display: grid;
					grid-template-columns: 1fr 1fr;
					gap: 30px;

					.listing-item {
						background: #FFFFFF 0 0 no-repeat padding-box;
						box-shadow: 0 3px 20px #00000029;
						border-radius: 5px;
					}
				}
			}
		}
	}
}
</style>
