<template lang="pug">
.actor-detail
    .actor-head
        img(:src="actorProfileImage")
        .actor-detail-info
            h4 {{ username }}
            .actor-characteristics
                .actor-col
                    .actor-gender
                        img(src="@/assets/images/icon-monstr-gender-5.svg")
                        img(v-if="data?.gender === 0" src="@/assets/images/Icon-awesome-male.svg")
                        img(v-else src="@/assets/images/Icon-awesome-female.svg")
                    .actor-filter-item
                        h4 თფ
                        .filter-item-circle
                    .actor-age
                        h4 ას
                        p {{ data?.age ? data?.age : "-" }}
                    .actor-weight
                        img(src="@/assets/images/Icon-awesome-weight.svg")
                        p {{ data?.weight ? data.weight : "-" }}
                .actor-col
                    .actor-skin
                        img(src="@/assets/images/Icon-ionic-md-hand.svg")
                        .actor-skin-color
                    .actor-eye-color
                        img(src="@/assets/images/Icon-awesome-eye.svg")
                        .actor-eye-color-circle(:class="{brown: 2 === 2}")
                    .actor-height
                        .actor-height-icon
                            img(src="@/assets/images/Icon-awesome-ruler.svg")
                            img(src="@/assets/images/Icon-awesome-male.svg")
                        p {{ data?.height ? data.height : "-" }}
                .actor-col
                    .actor-additional-item
                        img(src="@/assets/images/Icon-feather-plus.svg")
                        p {{ data?.other_characteristics[0].title ? data.other_characteristics[0].title : "-" }}
    .actor-divider

    .actor-on-result-bottom(v-if="onResult" )
        h3 ᲒᲐᲚᲔᲠᲔᲐ
        // wave component

    .actor-bottom(v-else )
        .wave-component
            audio(:src="data?.voice ? data.voice : ''" controls)

        carousel(v-if="data.images && data.images.length" :items-to-show="3")
            slide(v-for="slide in data.images" :key="slide")
                img.actor-detail-carousel-image(:src="slide.image")

            template(#addons)
                navigation

        .actor-contact-info
            h3 ᲡᲐᲙᲝᲜᲢᲐᲥᲢᲝ
            div
                .actor-phone-number(@click="showPhone=!showPhone")
                    img
                    span {{ showPhone && offerStatus === 1 ? actorPhone : '**** *** ** ** **' }}
                .actor-email(@click="showEmail=!showEmail")
                    img
                    span {{ showEmail && offerStatus === 1 ? actorEmail : '************@*****.***' }}
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'

export default {
    name: "ActorDetail",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        onResult: {
            type: Boolean,
            default: false
        },
        offerStatus: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            phone: "+995 555 55 55 55",
            email: "namelastname@gmail.com",
            showPhone: false,
            showEmail: false
        }
    },
    computed: {
        username() {
            return this.data.user.first_name.length ? this.data.user.first_name[0] + "." + this.data.user.last_name[0] : "-"
        },
        actorPhone() {
            return this.data.user.phone_number
        },
        actorEmail() {
            return this.data.user.email
        },
        actorProfileImage() {
            return this.data.user.profile_image !== null ? this.data.user.profile_image : require('../../assets/images/defaultImage.png')
        }
    },
    watch: {
        data: {
            handler() {
                this.showPhone = false
                this.showEmail = false
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.actor-detail {
    height: 100%;
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    > .actor-head {
        display: flex;
        gap: 10px;

        > img {
            width: 150px;
            height: 165px;
            border-radius: 10px;
            object-fit: cover;
        }

        .actor-detail-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            > h4 {
                font: normal normal bold 16px/19px 'Helvetica-Regular';
                letter-spacing: 0;
                color: #212121;
                margin-bottom: 20px;
            }

            > .actor-characteristics {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 15px;
                height: 100%;
                justify-content: space-between;

                img {
                    width: 16px;
                    height: 20px;
                }

                .actor-col {
                    display: grid;
                    grid-template-rows: 1fr 1fr 1fr 1fr;

                    .actor-gender, .actor-age, .actor-skin, .actor-height, .actor-filter-item, .actor-weight, .actor-eye-color, .actor-additional-item {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        flex: 1 0 21%;
                    }

                    .actor-age, .actor-filter-item {

                        h4 {
                            font: normal normal bold 14px/15px 'Helvetica-Bold';
                            color: #212121;
                        }

                        p {
                            font-size: 14px;
                            color: #6E6E6E;
                        }
                    }

                    .actor-skin, .actor-filter-item {

                        .actor-skin-color, .actor-filter-item-circle {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: #F0D895;
                        }

                        .actor-skin-color.black {
                            background-color: #000000;
                        }
                    }

                    .actor-height, .actor-weight, .actor-additional-item {

                        .actor-height-icon {
                            display: flex;
                            align-items: center;
                        }

                        p {
                            font-size: 14px;
                            color: #6E6E6E;
                        }
                    }

                    .actor-eye-color {
                        .actor-eye-color-circle {
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: #5751F4;
                        }
                    }
                }
            }
        }
    }

    .actor-detail-carousel-image {
        width: 143px;
        height: 143px;
        border-radius: 10px;
        object-fit: cover;
    }

    > .actor-divider {
        width: 100%;
        height: 1px;
        background-color: #DDDDDD;
    }

    > .actor-on-result-bottom {
        margin-left: 160px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            font: normal normal normal 14px/17px 'Helvetica-Regular';
            letter-spacing: 0;
            color: #212121;
        }
    }

    .actor-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .actor-contact-info {

            > h3 {
                font: normal normal normal 16px/17px 'Helvetica-Regular';
                letter-spacing: 0;
                color: #212121;
                margin-bottom: 15px;
            }

            > div {
                display: flex;
                align-items: center;
                gap: 15px;

                > .actor-phone-number, > .actor-email {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    cursor: pointer;

                    img {

                    }

                    span {
                        font: normal normal normal 14px/18px 'Helvetica-Regular';
                        letter-spacing: 0;
                        color: #4354B4;
                    }
                }
            }
        }
    }
}
</style>
